<template>
	<div id="preliminary" ref="preliminary">
		<div class="nav">
			<top-vue></top-vue>
		</div>
		<div style="width:1200px;margin:40px auto;background-color:#fff;">
			<div style="text-align: left;border-bottom: 1px solid rgb(201 201 201);padding:10px 10px;">
				<p style="font-size: 20px;">
					<i style="color: #ff0000;font-style: normal;padding-right:10px;">{{ showUsername }}</i>考生：
				</p>
				<h3 style="text-indent: 2em;margin-bottom: 5px;margin-top: 5px;">
					您好！根据您的做答情况，现给出最终分析报告如下：</h3>
			</div>
			<div class="text">
				<div class="content">
					<h2>一、兴趣、能力、职业倾向</h2>
					<div class="tableContent">
						<div class="left">
							<el-table :data="typeData" border style="width: 100%">
								<el-table-column prop="lx" label="类型">
								</el-table-column>
								<el-table-column prop="xq" label="兴趣">
								</el-table-column>
								<el-table-column prop="nl" label="能力">
								</el-table-column>
								<el-table-column prop="qx" label="职业倾向">
								</el-table-column>
							</el-table>
						</div>
						<div class="right" style="width:100%;height: 385px;text-align: center;">
							<span style="background:#7b68ee;padding: 0px 8px;margin-right: 5px;"></span><span
								style="margin-right: 10px;">兴趣</span>
							<span style="background:#cd3700;padding: 0px 8px;margin-right: 5px;"></span><span
								style="margin-right: 10px;">能力</span>
							<span style="background:#8b8b00;padding: 0px 8px;margin-right: 5px;"></span><span
								style="margin-right: 10px;">职业倾向</span>
							<div ref="main" id="byLine" style="width: 100%; height: 100%">
								<img :src="tableImgUrl1" alt="" style="width: 100%;height: 100%;">
							</div>
						</div>
					</div>
				</div>
				<div class="content">
					<div class="tableContent">
						<div class="left">
							<el-table :data="scoreData" border style="width: 100%;background-color:#f2f2f2;">
								<el-table-column prop="lx" label="类型">
								</el-table-column>
								<el-table-column prop="max" label="最高分">
								</el-table-column>
								<el-table-column prop="avg" label="平均分">
								</el-table-column>
							</el-table>
						</div>
						<div class="right">
							<div ref="main" id="myLine" style="width: 100%; height: 100%">
								<img :src="tableImgUrl2" alt="" style="width: 100%;height: 100%;">
							</div>
						</div>
					</div>
				</div>
				<div class="content">
					<div>
						<tablel-vue :tableData="topList" v-if="topList.length > 0"></tablel-vue>
					</div>
				</div>
				<div class="content">
					<h2>二、性格代码：{{ mbtiCode }}</h2>
				</div>
				<div class="content">
					<h2>三、最终分析报告</h2>
					<div class="reportContent">
						{{ finalReport.content }}
					</div>
					<div class="recommendMojor">
						<h3>以下为老师推荐的十个专业填报方向</h3>
						<h4>{{ finalReport.name }}</h4>
					</div>
					<div class="imgs" v-for="item in finalReport.list" :key="item.id">
						<img :src="imgBaseURL + item.url" :alt="item.name" v-if="item.url">
					</div>
				</div>
				<div style="width: 100px;height: 100px;margin: 0 auto;">
					<el-button class="button" @click="dialogVisible = true"
						style="border: none;padding: 15px 73px;color: #fff;font-size: 20px;font-weight: bold; border-radius: 25px;">
						打印</el-button>
				</div>
			</div>
			<el-dialog title="打印报告预览" :visible.sync="dialogVisible" :close-on-click-modal="false" width="80%">
				<finalPrint :tableImgUrl="{ img1: tableImgUrl1, img2: tableImgUrl2 }" :finalReport="finalReport"
					:mbtiCode="mbtiCode" :topList="topList" :typeData="typeData" :scoreData="scoreData"></finalPrint>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {
	mapState,
} from "vuex"
import tablelVue from '@/components/tablel.vue';
import topVue from '@/components/top.vue';
import Cookies from "js-cookie"
import * as systemApi from "@/api/subjectSystemApi"
import finalPrint from './finalPrint'
export default {
	data() {
		return {
			dialogVisible: false,
			tableImgUrl1: '',
			tableImgUrl2: '',
			imgBaseURL: 'https://api.xueshitongedu.cn/upfiles/',
			//最终报告
			finalReport: {
				list: [],
				content: '',
				name: '',
			},
			//mbti性格
			mbtiCode: '',
			//得分最高的三项专业
			topList: [],
			//兴趣、能力、职业倾向
			typeData: [{
				lx: '技术',
				syS_TestAnswerType: '1',
				xq: 0,
				nl: 0,
				qx: 0
			},
			{
				lx: '研究',
				syS_TestAnswerType: '2',
				xq: 0,
				nl: 0,
				qx: 0
			},
			{
				lx: '艺术',
				syS_TestAnswerType: '3',
				xq: 0,
				nl: 0,
				qx: 0
			},
			{
				lx: '社会',
				syS_TestAnswerType: '4',
				xq: 0,
				nl: 0,
				qx: 0
			},
			{
				lx: '经营',
				syS_TestAnswerType: '5',
				xq: 0,
				nl: 0,
				qx: 0
			},
			{
				lx: '事务',
				syS_TestAnswerType: '6',
				xq: 0,
				nl: 0,
				qx: 0
			},
			{
				lx: '自然',
				syS_TestAnswerType: '7',
				xq: 0,
				nl: 0,
				qx: 0
			},
			],
			//类型	最高分	平均分
			scoreData: [{
				lx: '技术',
				max: 11,
				avg: 0,
			},
			{
				lx: '研究',
				max: 11,
				avg: 0,
			},
			{
				lx: '艺术',
				max: 11,
				avg: 0,
			},
			{
				lx: '社会',
				max: 11,
				avg: 0,
			},
			{
				lx: '经营',
				max: 11,
				avg: 0,
			},
			{
				lx: '事务',
				max: 11,
				avg: 0,
			},
			{
				lx: '自然',
				max: 11,
				avg: 0,
			},
			],
		}
	},
	computed: {
		showUsername() {
			return this.subjectData.studentName;
		},
		...mapState(["majorStatus", "subjectData"]),
	},
	components: {
		topVue,
		tablelVue,
		finalPrint,
	},
	created() {
		this.getStatus()
	},
	methods: {
		getStatus() {
			if (this.majorStatus == '') {
				let status = sessionStorage.getItem('majorStatus');
				console.log(status);
				this.$store.commit('setMajorStatus', status);
			}
			if (Object.keys(this.subjectData).length == 0) {
				let subjectData = JSON.parse(sessionStorage.getItem('subjectData'));
				console.log(subjectData);
				this.$store.commit('setSubjectData', subjectData);
			}

			if (this.majorStatus == '0') {
				this.$router.push('introduce');
			} else if (this.majorStatus == '1' || this.majorStatus == '2' || this.majorStatus == '3') {
				this.$router.push('mojor');
			} else if (this.majorStatus == '5') {
				this.$router.push('preliminary');
			}
			this.getReport()
		},
		getReport() {
			let query = {
				studentName: this.subjectData.studentName,
				mobileTel: this.subjectData.mobileTel,
				id:this.subjectData.id
			}
			//获取最终报告图片
			systemApi.getMemberInfo(query).then(res => {
				console.log(res);
				let r = res.result;

				let idList = r.recommendSubjectIDs.split(',');
				let urlList = r.recommendSubjectImgUrls.split(',');
				let nameList = r.recommendSubjectNames.split(',');

				for (let i = 0; i < idList.length; i++) {
					this.finalReport.list.push({
						id: idList[i],
						url: urlList[i],
						name: nameList[i],
					})
				}
				this.mbtiCode = r.mbtiResult;
				this.finalReport.content = r.commentContent;
				this.finalReport.name = r.recommendSubjectNames;
				this.tableImgUrl1 = r.reportImg1;
				this.tableImgUrl2 = r.reportImg2;
				//this.finalList = res.result.recommendSubjectImgUrls.split(',');
			})
			systemApi.getTestList({
				subjectTestId: this.subjectData.id
			}).then(res => {
				console.log(res);
				res.result.map(item => {
					if (item.syS_TestQuestion == 1) {
						this.typeData[item.syS_TestAnswerType - 1].xq += item.amount
					} else if (item.syS_TestQuestion == 2) {
						this.typeData[item.syS_TestAnswerType - 1].nl += item.amount
					} else if (item.syS_TestQuestion == 3) {
						this.typeData[item.syS_TestAnswerType - 1].qx += item.amount
					}
				})
				//console.log(this.typeData);
				let avg = [0, 0, 0, 0, 0, 0, 0],
					sum = [0, 0, 0, 0, 0, 0, 0];
				//遍历取数据并将数据插入柱状图数据
				for (let i = 0; i < this.typeData.length; i++) {
					//总分
					sum[i] += (this.typeData[i].xq + this.typeData[i].nl + this.typeData[i].qx);
				}
				//雷达图左边数据
				for (let i = 0; i < this.scoreData.length; i++) {
					//计算平均分
					avg[i] = sum[i] / 3;
					this.scoreData[i].avg = avg[i].toFixed(2);
				}

				//取最高三个
				let listQuery = {
					limit: 999,
					id: this.subjectData.id
				}
				systemApi.getTopInfo().then(infoRes => {
					console.log(infoRes)
					let tempList = [...this.typeData];
					tempList.sort((a, b) => {
						return (b.xq + b.nl + b.qx) - (a.xq + a.nl + a.qx)
					})
					for (let i = 0; i < 3; i++) {
						this.topList.push({
							lx: tempList[i].lx,
							sm: infoRes.data.find(u => {
								return u.syS_TestAnswerType == tempList[i]
									.syS_TestAnswerType
							}).introduce,
							dx: infoRes.data.find(u => {
								return u.syS_TestAnswerType == tempList[i]
									.syS_TestAnswerType
							}).classJobs,
							zy: infoRes.data.find(u => {
								return u.syS_TestAnswerType == tempList[i]
									.syS_TestAnswerType
							}).schoolSubjects,
						})
					}
				})

			})
		},
	}
}
</script>
<style scoped>
.cell {
	text-align: center;
}

.button.el-button {
	background: #9eacfd;
}

.button.el-button:focus,
.button.el-button:hover {
	background: #9eacfd !important;
}

.content {
	margin: 0 20px;
	text-align: left;
}

.content h2 {
	background: #9eacfd;
	color: #fff;
	border-radius: 10px;
	margin: 0;
	padding: 20px 10px;
	margin: 20px 0;
	text-align: left;
}

.content h3 {
	text-align: center;
	padding: 30px 0;
}

.content .tableContent {
	display: flex;
	margin: 30px 0;

}

.content .tableContent .left,
.content .tableContent .right {
	margin: 0 10px;
	flex: 1;
}

.content .reportContent {
	margin: 40px 0;
}

.content .recommendMojor {
	margin: 30px 0;
}

.content .imgs {
	margin-top: 10vh;
	width: 100%;

}

.content .imgs img {
	width: 100%;
}
</style>
