<template>
	<div id="preliminary" ref="preliminary">
		<div ref="container" id="container" style="width:100%;margin:40px auto;background-color:#fff;">
			<div style="text-align: left;border-bottom: 1px solid rgb(201 201 201);padding:10px 10px;">
				<p style="font-size: 20px;">
					<i style="color: #ff0000;font-style: normal;padding-right:10px;">{{showUsername}}</i>考生：
				</p>
				<h3 style="text-indent: 2em;margin-bottom: 5px;margin-top: 5px;">
					您好！根据您的做答情况，现给出最终分析报告如下：</h3>
			</div>
			<div class="text">
				<div class="content">
					<h2>一、兴趣、能力、职业倾向</h2>
					<div class="tableContent">
						<div class="left">
							<el-table :data="typeData" border style="width: 100%">
								<el-table-column prop="lx" label="类型">
								</el-table-column>
								<el-table-column prop="xq" label="兴趣">
								</el-table-column>
								<el-table-column prop="nl" label="能力">
								</el-table-column>
								<el-table-column prop="qx" label="职业倾向">
								</el-table-column>
							</el-table>
						</div>
						<div class="right" style="width:100%;height: 385px;text-align: center;">
							<span style="background:#7b68ee;padding: 0px 8px;margin-right: 5px;"></span><span
								style="margin-right: 10px;">兴趣</span>
							<span style="background:#cd3700;padding: 0px 8px;margin-right: 5px;"></span><span
								style="margin-right: 10px;">能力</span>
							<span style="background:#8b8b00;padding: 0px 8px;margin-right: 5px;"></span><span
								style="margin-right: 10px;">职业倾向</span>
							<div ref="main" id="byLine" style="width: 100%; height: 100%">
								<img :src="tableImgUrl.img1" alt="" style="width: 100%;height: 100%;">
							</div>
						</div>
					</div>
				</div>
				<div class="content">
					<div class="tableContent">
						<div class="left">
							<el-table :data="scoreData" border style="width: 100%;background-color:#f2f2f2;">
								<el-table-column prop="lx" label="类型">
								</el-table-column>
								<el-table-column prop="max" label="最高分">
								</el-table-column>
								<el-table-column prop="avg" label="平均分">
								</el-table-column>
							</el-table>
						</div>
						<div class="right">
							<div ref="main" id="myLine" style="width: 100%; height: 100%">
								<img :src="tableImgUrl.img2" alt="" style="width: 100%;height: 100%;">
							</div>
						</div>
					</div>
				</div>
				<div class="content">
					<div>
						<tablel-vue :tableData="topList"></tablel-vue>
					</div>
				</div>
				<div class="content">
					<h2>二、性格代码：{{mbtiCode}}</h2>
				</div>
				<div class="content">
					<h2>三、最终分析报告</h2>
					<div class="reportContent">
						{{finalReport.content}}
					</div>
					<div class="recommendMojor">
						<h3>以下为老师推荐的十个专业填报方向</h3>
						<h4>{{finalReport.name}}</h4>
					</div>
					<div class="imgs" v-for="item in finalReport.list" :key="item.id">
						<img :src="imgBaseURL + item.url" :alt="item.name">
					</div>
				</div>
				<div style="width: 100px;height: 100px;margin: 0 auto;">
					<el-button class="button" v-print="'#container'"
						style="border: none;padding: 15px 73px;color: #fff;font-size: 20px;font-weight: bold; border-radius: 25px;">
						打印</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import tablelVue from '@/components/tablel.vue';
	import Cookies from "js-cookie"

	export default {
		props:{
			tableImgUrl:Object,
			finalReport:Object,
			mbtiCode:String,
			topList:Array,
			typeData:Array,
			scoreData:Array
		},
		data() {
			return {
				imgBaseURL: 'https://api.xueshitongedu.cn/api',
			}
		},
		computed: {
			showUsername() {
				return Cookies.get('username')
			}
		},
		components: {
			tablelVue,
		},
		mounted() {
			
		},
		methods: {
		}
	}
</script>
<style scoped>
	.cell {
		text-align: center;
	}

	.button.el-button {
		background: #9eacfd;
	}

	.button.el-button:focus,
	.button.el-button:hover {
		background: #9eacfd !important;
	}

	.content {
		margin: 0 20px;
		text-align: left;
	}
	
	.content h2 {
		background: #9eacfd;
		color: #fff;
		border-radius: 10px;
		margin: 0;
		padding: 20px 10px;
		margin: 20px 0;
		text-align: left;
	}
	
	.content h3 {
		text-align: center;
		padding: 30px 0;
	}
	
	.content .tableContent {
		display: flex;
		margin: 30px 0;
	
	}
	
	.content .tableContent .left,
	.content .tableContent .right {
		margin: 0 10px;
		width: 50%;
	}
	
	.content .reportContent {
		margin: 40px 0;
	}
	
	.content .recommendMojor {
		margin: 30px 0;
	}
	
	.content .imgs {
		margin-top: 10vh;
		width: 100%;
	
	}
	
	.content .imgs img {
		width: 100%;
	}
</style>
