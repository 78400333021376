<template>
	<div id="table">
		<div class="tableContent">
			<h2 class="title">您得分最高的类型为：{{tableData[0].lx}}</h2>
			<div class="sm">
				<h2>类型说明：</h2>
				{{tableData[0].sm}}
			</div>
			<div class="dx">
				<h2>典型职业：</h2>
				{{tableData[0].dx}}
			</div>
			<div class="zy">
				<h2>大学专业：</h2>
				{{tableData[0].zy}}
			</div>
		</div>
		<div class="tableContent">
			<h2 class="title">您得分第二高的类型为：{{tableData[1].lx}}</h2>
			<div class="sm">
				<h2>类型说明：</h2>
				{{tableData[1].sm}}
			</div>
			<div class="dx">
				<h2>典型职业：</h2>
				{{tableData[1].dx}}
			</div>
			<div class="zy">
				<h2>大学专业：</h2>
				{{tableData[1].zy}}
			</div>
		</div>
		<div class="tableContent">
			<h2 class="title">您得分第三高的类型为：{{tableData[2].lx}}</h2>
			<div class="sm">
				<h2>类型说明：</h2>
				{{tableData[2].sm}}
			</div>
			<div class="dx">
				<h2>典型职业：</h2>
				{{tableData[2].dx}}
			</div>
			<div class="zy">
				<h2>大学专业：</h2>
				{{tableData[2].zy}}
			</div>
		</div>

	</div>

</template>
<script>
	export default {
		data() {
			return {}
		},
		props: {
			tableData: Array
		},
		methods: {},
	}
</script>
<style rel="stylesheet/scss" scoped>
	#table{
		padding: 30px 0;
	}
	.tableContent {
		width: calc(100% - 50px);
		margin: 15px;
		border: 1px solid #969696;
		padding: 5px 10px;
	}

	.title {
		text-align: center;
	}

	.sm,
	.dx,
	.zy {
		margin: 20px 0;
		font-size: 18px;
		text-align: left;
	}
</style>
